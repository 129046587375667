@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 55px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }